.hero {
    height: 100%;
    width: 100%;
}

.hero-btn{
    padding: 0.6rem 1rem;
    text-transform: uppercase;
    background: #fb5e2a;
    color: #fff;
    border: 1px solid #fb5e2a;
    font-weight: 200;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 1px;
    margin-top: 1rem;
  }

.mask {
    width: 100%;
    height: 100vh;
    position: relative;
    
}

.mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.3;
 }

.bg-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.left-content {
    position: absolute;
    top: 30%;
    left: 19%;
}

.left-content-images {
    display: flex;
}

.left-content-images img {
margin-right: 2rem;

}


.left-content h1 {
    color: #fff;
    font-size: 3rem;
    width: 31.4rem;
    margin-bottom: 0.8rem;
}

.left-content p {
    color: #fff;
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0.5rem 0 0.5rem 0;
}

.left-content button {
    color: #fff;
}

@media screen and (max-width: 960px) {

    .left-content {
        left: 10%;
    }



    .left-content h1 {
        width: 20rem;
        margin-top: 0.8rem;
    }

    .left-content-images img {
        width: 120px;
        height: 80px;
    }
  }