.card {
    /* border: 1px solid #ddd; */
    padding: 20px;
    margin: 10px;
    text-align: center;
    width: 18rem;
    height: 21rem;
    border-radius: 20px;
    transition: background-color 0.3s, box-shadow 0.3s;
  }

  .card img {
    max-width: 100%;
    
  }

  .card h3 {
    color: #393835;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0.6rem 0 0.5rem 0;
    padding: 0 2rem 0 2rem;
  }

  .card p {
    color: #393835;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.5;
    padding: 0 2rem 0 2rem;
    margin: 0.1rem 0 0.1rem 0;
  }

  .card:hover {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .card:hover h3 {
    color: #fb5e2a;
    
  }
  
  .card:hover p {
    color: grey;
  }