.about2 {
    width: 100%;
    display: flex;
    background-color: #f3edfc;
    align-items: center;
    justify-content: center;
    padding: 5rem 0 4rem 0;
    
}

.about2 p {
   
   font-size: 1.15rem;
   font-weight: 400;
   line-height: 1.4;
   margin-top: 0.5rem;
   width: 60rem;
   text-align: center;
}

@media screen and (max-width: 960px) {
    .about2 p {
        width: 100%;
    }
}