.aboutUsHero {
  width: 100%;
  background-color: #2e2125;
  display: flex;
  flex-direction: column;
  
}


.aboutUsHero-image img {
    width: 86%;
    margin-top: -250px; 
    float: right;
}

.aboutUsHero-text {
    display: flex;
    margin: 4rem 0 2rem 0;
    justify-content: space-around;
}

.aboutUsHero-text p {
    color: #ffffff;
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    padding-left: 6rem;
    
}

.aboutUsHero-text ul {
    list-style: none;
    line-height: 1.5;
    padding-right: 6rem;

}

.aboutUsHero-text ul li {
    font-weight: 600;
    color: #fb5e2a;
    font-size: 1.4rem;
    text-align: end;
}

@media screen and (max-width: 960px) {

    .aboutUsHero-text {
        margin: 64px 10px 32px 10px;
    }


    .aboutUsHero-text ul {
        padding-right: 0;
    }

    .aboutUsHero-text p {
        padding-left: 0;
    }

    .aboutUsHero-image img {
        margin-top: -153px;
    }
}