.clientsIntro {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #f3edfc;

}


.clientsIntro-content {
    width: 60rem;
    display: flex;
    flex-direction: column;
    padding: 10rem 0 4rem 18rem;  
} 


.clientsIntro-content h4 {
    font-size: 0.9rem;
    font-weight: 400;
    color:#533c76;
}

.clientsIntro-content a {
    text-decoration: none;
    color:#533c76;
}

.clientsIntro-content h1 {
    font-size: 3rem;
    margin: 1rem 0 1.5rem 0;
    width: 22rem;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: normal;
    color: #393835;
    padding-left: 3rem;
    margin: 1rem 0 1rem 0;
}

.clientsIntro-content p {
    padding-left: 3rem;
    font-size: 1.15rem;
    line-height: 1.5;
    font-weight: 400;
    margin: 0.5rem 0 1rem 0;
}

.images {
    margin-left: 3rem;
}

.images img {
    padding: 1rem 1rem 1rem 0;
}

@media  screen and (max-width: 960px) {
    .clientsIntro-content {
        padding-left: 2rem;
        width: auto;

    }

    .clientsIntro-content h1 {
        font-size: 1.8rem;
        width: auto;
        padding-left: 1rem;
        padding-right: 3rem;
    }

    .clientsIntro-content p {
        padding-left: 1rem;
        width: 260px;
    }
}