.servicesIntro {
    width: 100%;
    background-color: #f3edfc;
    display: flex;
    flex-direction: column;
    
}

.servicesIntro-content {
    padding: 10rem 0 4rem 18rem;
}

.servicesIntro-content a {
    text-decoration: none;
    color: #533c76;
}


.servicesIntro-content h4 {
    font-weight: 400;
    font-size: 0.9rem;
    color: #533c76;
}

.servicesIntro-content h1{
    width: 22rem;
    color: #393835;
    padding-left: 4rem;
    font-size: 3rem;
    line-height: 1.2;
    font-weight: bold;
    letter-spacing: normal;
}

@media screen and (max-width: 960px) {
    .servicesIntro-content {
        padding: 8rem 0 2rem 2rem;
    }

    .servicesIntro-content h1 {
        padding-left: 1rem;
        font-size: 1.5rem;
        width: 12rem;
    }

    
}