.about {
  width: 100%;
  background-color: #f3edfc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-content {
  /* border: 1px solid black; */
  text-align: center;
  max-width: 1000px;
  margin: 4rem 0 3rem 0;
}

.about-content-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 3rem 0;
}

.about-content-image img {
  margin-right: 30px;
}

.about-content p {
  padding: 0 0.5rem 0 0.5rem;
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 0.5rem;
}

@media screen and (max-width: 960px) {
  .about-content img {
    padding: 15px 10px 15px 10px;
    margin: 0;
  }

  .about-content p {
    padding: 0 1rem;
  }
}
