.brands {
    background-color: #f3edfc;
    display: flex;
    padding: 6rem 2rem 4rem 2rem;
    flex-direction: column;
    align-items: center;
}

.brands h3 {
    color: #393835;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 0;
    margin-top: 0;
}

.brands-content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    justify-items: center;
    margin: 4rem 0 2rem 0;
}

.brands-content img {
    margin: 0 0 2rem 0;
}

@media screen and (max-width: 960px) {

    .brands {
        padding: 6rem 1rem 4rem 1rem;
    }

    .brands h3 {
        text-align: center;
    }



    .brands-content {
        grid-template-columns: repeat(3, 1fr);
    }

    .brands-content img {
        padding: 1rem 1rem 1rem 1rem;
        height: auto;
        width: 80px;
    }
}


