.whatIs {
    display: flex;
    background-color: #f3edfc;
    justify-content: center;
    padding: 5rem 0 4rem 0;
}

.whatIs-text {
    /* border: 1px solid black; */
    width: 28rem;
    margin-right: 2rem;
    
}

.whatIs-text h1 {
    color: #393835;
    font-size: 2.4rem;
    font-weight: 600;
    
    
}
.whatIs-text p {
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 1.5;
    color: #393835;
}

.whatIs-picture {
    width: 30rem;
    /* border: 1px solid black; */
    height: 35rem;
    
}

.whatIs-picture img {
 width: 100%;
 height: auto;
 vertical-align: middle;

}

@media screen and (max-width: 960px) {
    .whatIs {
        flex-direction: column;
    }

    .whatIs-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .whatIs-text p {
        padding: 0 15px;
    }

    .whatIs-text h1 {
        font-size: 1.8rem;
    }

    .whatIs-picture {
        width: 100%;
        display: flex;
        justify-content: center;
        height: auto;
    }

    .whatIs-picture img {
        width: 300px;
        height: auto;
    }
}

