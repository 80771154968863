.contactUsIntro {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f3edfc;
}

.contactUsIntro-content {
  padding: 10rem 0 2rem 18rem;
}

.contactUsIntro-content a {
  text-decoration: none;
  color: #533c76;
}

.contactUsIntro-content h4 {
  font-weight: 400;
  font-size: 0.9rem;
  color: #533c76;
}

.contactUsIntro-content h1 {
  color: #393835;
  padding-left: 4rem;
  font-size: 3rem;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: normal;
}

@media screen and (max-width: 960px) {
  .contactUsIntro-content {
    padding-left: 2rem;
  }

  .contactUsIntro-content h1 {
    padding-left: 1rem;
    font-size: 1.8rem;
  }

  
}
