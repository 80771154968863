.benefits {
  background-color: #f3edfc;
  /* border: 1px solid black; */
  padding: 5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.benefits h4{
 color: #fb5e2a;
 font-weight: 600;
 font-size: 1.3rem;
}

.benefits h2{
 font-size: 2.4rem;
 font-weight: bold;
 line-height: 1.2;
 text-align: center;
 letter-spacing: normal;
 color: #393835;
 margin-top: 0;
 width: 24rem;
}

.benefits-container {
  /* border: 1px solid black; */
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 1rem;
  justify-content: center;
  width: 70rem;
}

.benefit {
  /* border: 1px solid black; */
  margin: 1rem 1rem;
  width: 16rem;
  padding: 0 1rem;
}

.benefit h1 {
  font-size: 4rem;
  font-weight: 400;
  color: #fb5e2a;
  margin: 2rem 0;
}

.benefit h3 {
  color: #393835;
  font-size: 1.5rem;
  font-weight: 600;
}

.benefit p {
  color: #393835;
  font-size: 1.1rem;
  margin-top: 1rem;
}


@media screen and (max-width: 960px) {
  .benefits-container {
    width: 100%;
  }

  .benefits h2 {
    width: 17rem;
    font-size: 1.4rem;
  }

  .benefit p {width: 15rem;}
}