.whatIsExplainer {
 background-color: #f3edfc;
 display: flex;
 justify-content: center;
 padding: 5rem 0 4rem 0;
}

.whatIsExplainer-text h1 {
    color: #393835;
    font-weight: 600;
    font-size: 2.5rem;
    width: 30rem;
}

.whatIsExplainer-text p {
    width: 28rem;
    font-size: 1.1rem;
    color: #393835;
    font-weight: 400;
    margin-top: 1.5em;
    line-height: 1.5;
} 

.whatIsExplainer-text ul {
    list-style: none;
    padding-left: 0;
    
}

.whatIsExplainer-text ul li {
    font-size: 1.15rem;
    margin: 1rem 0 1rem 0;

}

.whatIsExplainer-text ul li img {
    margin-right: 1rem;
}

.whatIsExplainer-picture img {
    padding-top: 6rem;
    
}

@media screen and (max-width: 960px) {
    .whatIsExplainer {
        display: block;
        padding: 40px 0;
    }

    .whatIsExplainer-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .whatIsExplainer-text h1 {
     width: 22rem;
    }

    .whatIsExplainer-text p {
        width: auto;
        padding: 0 15px;
    }

    .whatIsExplainer-text ul {
        text-align: start;
    }

    .whatIsExplainer-picture {
        display: flex;
        justify-content: center;
    }

    .whatIsExplainer-picture img {
        width: 300px;
        height: auto;
        padding-top: 2rem;
    }


}

