.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  z-index: 10;
}

.navbar-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  transition: 0.5s;
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 10;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-menu li {
  list-style: none;
}

.nav-menu-logo {
  /* border: 1px solid black; */
  margin: 0 18rem 0 0;
}

.nav-menu-logo a {
  display: flex;
}

.nav-menu-list {
  /* border: 1px solid white; */
}

.nav-menu-list ul {
  padding-left: 0px;
}

.nav-menu-list ul li {
  /* display: flex; */
}

.navbar-btn {
  padding: 8px 1rem;
  margin: 0 0.5rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  font-weight: 300;
  cursor: pointer;
  white-space: nowrap;
}

.navbar-btn-color {
  padding: 8px 1rem;
  margin: 0 0.5rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  background: transparent;
  color: #000;
  border: 1px solid #000;
  font-weight: 300;
  cursor: pointer;
  white-space: nowrap;
}

.dropdown-container button {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.dropdown-btn {
  color: #fff;
  background: none;
  margin: 0 0.5rem;
  cursor: pointer;
}

.dropdown-btn-color {
  border: 0;
  color: #000;
  background: none;
  margin: 0 0.5rem;
  cursor: pointer;
}

/* .dropdown-container {
  display: flex;
 
} */

.dropdown-menu {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* border: 1px solid black; */

}

.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  left: 0;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  height: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  /* border: 1px solid black; */
  max-height: 480px;
  overflow-y: auto;
}

.dropdown-content.show {
  opacity: 1;
  visibility: visible;
}

.dropdown-content h3 {
  color: #323f53;
  text-align: start;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  margin: 0;
  width: 100%;
  font-weight: 600;
}

.dropdown-menu {
  padding: 1.5rem 0 1rem 0;
}

.dropdown-content p {
  color: black;
}

.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: #333;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.services-container {
  display: flex;
  flex-wrap: wrap;

}

.services-container a {
  padding: 0 10px 0 0;
  text-decoration: none;
  color: #fff;
  box-sizing: border-box;
}

.services-container a:hover {
  background-color: #e6e3ee;
}

.services {
  width: 17.5rem;
  margin-right: 1rem;
  padding: 0.1rem 0.2rem;
}

.services h4 {
  color: #323f53;
  font-size: 1.15rem;
  font-weight: 600;
  margin: 1rem 0 0.5rem 0;
}

.services p {
  color: #606267;
  margin: 0.5rem 0 0.3rem 0;
  font-size: 0.9rem;
  font-weight: 400;
}

.hamburger {
  display: flex;
  cursor: pointer;
}

.overlay-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 33, 37, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.overlay-menu ul {
  list-style: none;
  text-align: center;
}

.overlay-menu li {
  margin: 20px;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}

.overlay-menu a {
  text-decoration: none;
  color: #ffffff;
}

.overlay-header {
  display: flex;
  width: 100%;
  position: absolute;
  top: 1%;
  align-items: center;
  justify-content: space-between;
}

.overlay-header img {
  padding-left: 20rem;
}

.close-icon {
  padding-right: 20rem;
  cursor: pointer;
}

.nav-menu.active .overlay-menu {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 960px) {

  .nav-menu-logo {
    margin: 0 14rem 0 0;
  }

  .nav-menu-logo img {
    width: 65px;
    height: auto;
    margin-right: 35px;
  }

  .nav-menu-list {
    display: none;
  }

  .overlay-menu ul {
    padding-left: 0;
  }

  .overlay-header img {
    padding-left: 0;
  }

  .close-icon {
    padding-right: 0;
  }
}