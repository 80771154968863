.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #2e2125;
}

.footer-content {
  display: flex;
  margin: 6rem 0 4rem 0;
  
}

.footer-content-left {
  display: flex;
}

.footer-logo {
  display: flex;
  padding: 2rem 2rem 0 1rem;
}

.footer-about-info {
  color: #ffffff;
}

.footer-list {
  display: flex;
  margin: 0 2rem 0 2rem;
}

.footer-list-content {
  margin: 0 2rem 0 2rem;
}

.footer-list-content a {
  text-decoration: none;
  color: #ffffff;
}

.footer-list-content h3 {
  color: #ffffff;
}

.footer-list-content ul {
  list-style: none;
  padding-left: 0;
}

.footer-list-content h3 {
  font-weight: 600;
  font-size: 1.15rem;
  line-height: 1.3;
  letter-spacing: normal;
}

.footer-list-content ul li {
  color: #ffffff;
  font-size: 0.9rem;

}

.footer-bottom {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 2rem 0 1rem 0;

}

.footer-bottom p {
  font-size: 0.8rem;
  font-weight: 300;
  color: #ffffff;
  padding: 0;
}

.footer-bottom a {
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
}

@media screen and (max-width: 960px) {
  .footer-bottom p {
    padding: 0;
  }

  .footer-list-content {
    margin: 0 0 0 45px;
}

  .footer-content {
    display: block;
    margin-top: 4rem;
  }

  .footer-list {
    margin: 40px 0;
  }

  .footer-bottom {
    justify-content: space-between;
    margin: 0;
  }

  .footer-bottom p {
    padding: 0 5px;
  }

  .footer-about {
    padding-left: 10px;
  }
}
