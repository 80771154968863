.why-us {
  background-color: #f3edfc;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.why-us h4 {
  color: #fb5e2a;
  margin: 1rem 0 1rem 0;
  font-weight: 600;
  font-size: 1.3rem;
}

.why-us h2 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: #393835;
  margin: 0.5rem 0 4rem 0;
}

.why-us-container {
  width: 80rem;
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid black; */
  justify-content: center;
}

.why-us-reason {
  /* border: 1px solid black; */
  width: 20rem;
  margin: 1.5rem 1rem 1rem 0;
  display: flex;
}

.why-us-reason img {
  padding-top: 0.5rem;
  margin: 0.3rem 1rem 0 0.5rem;
}

.content {
  /* border: 1px solid black; */
  width: 15rem;
}

.content h3 {
  color: #393835;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0.5rem 0 1rem 0;
}

.content p {
  font-size: 1.1rem;
  padding: 0 1.5rem 0 0;
  color: #393835;
  line-height: 1.5;
  font-weight: 400;
  margin: 0.5rem 0 0.6rem 0;
}

@media screen and (max-width: 960px) {
  .why-us-container {
    width: 100%;
  }
}
