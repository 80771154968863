.ourProcessHero {
    width: 100%;
    background-color: #f3edfc;
    display: flex;
}


.ourProcessHero-content {
    width: 100%;
    padding: 2rem 0 1rem 0;
    
}

.ourProcessHero-content img {
    width: 86%;
    float: right;
}