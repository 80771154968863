.whatWeDo {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f3edfc;
    align-items: center;
    justify-content: center;
}

.whatWeDo-content {
    margin: 4rem 4rem;
    /* border: 1px solid black; */
    width: 800px;
    text-align: center;

}

.whatWeDo-content h4 {
 color: #fb5e2a;
 font-weight: 600;
 font-size: 1.3rem;
}

.whatWeDo-content h2 {
 font-size: 38px;
 font-weight: bold;
 color: #393835;
 letter-spacing: normal;
 line-height: 1.2;
 margin: 20px 0 10px 0;
}

.whatWeDo-content p {
 color: #393835;
 font-size: 18px;
 line-height: 1.5;
 font-weight: 400;
}

@media screen and (max-width: 960px) {


    .whatWeDo-content {
        width: 100%;
        margin: 4rem 0rem;
    }



    .whatWeDo-content h2 {
        font-size: 1.5rem;
    }
  }