.howWeWork {
  background-color: #f3edfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.howWeWork-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.howWeWork-content-top {
  text-align: center;
  margin: 4rem 0 6rem 0;
}

.howWeWork-content-top h4 {
  color: #fb5e2a;
  font-weight: 600;
}

.howWeWork-content-top h1 {
  font-size: 2.4rem;
  color: #393835;
  font-weight: bold;
  letter-spacing: normal;
  margin: 1rem 0 1rem 0;
}

.howWeWork-content-top p {
  color: #393835;
  font-weight: 400;
  font-size: 1.15rem;
}

.howWeDoThings {
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0rem 0;
  padding: 0 0 6rem 0;
  border-bottom: 1px solid #ddd;
}

.howWeDoThings-content {
  display: flex;
  justify-content: center;
  width: 50rem;
}

.howWeDoThings-content-left {
  border-right: 1px solid #fb5e2a;
  width: 25rem;
  text-align: end;
}

.howWeDoThings-content-left img {
  padding-right: 6rem;
}

.howWeDoThings-content-left h4 {
  color: #fb5e2a;
  font-weight: 600;
  padding-right: 6rem;
}

.howWeDoThings-content-left h1 {
  color: #393835;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: normal;
  padding-right: 6rem;
}

.howWeDoThings-content-left p {
  font-size: 1.15rem;
  color: #393835;
  font-weight: 400;
  padding-right: 6rem;
}

.howWeDoThings-content-right {
  width: 25rem;
}

.howWeDoThings-content-right img {
  padding-left: 6rem;
}

.howWeDoThings-content-right h4 {
  color: #fb5e2a;
  font-weight: 600;
  padding-left: 6rem;
}

.howWeDoThings-content-right h1 {
  color: #393835;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: normal;
  padding-left: 6rem;
}

.howWeDoThings-content-right p {
  font-size: 1.15rem;
  color: #393835;
  font-weight: 400;
  padding-left: 6rem;
}

.point {
  background: #fb5e2a;
  border-radius: 50px;
  border: #fff 1px solid;
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 751px;
}

@media screen and (max-width: 960px) {


  .howWeDoThings {
    padding-bottom: 2rem;
  }


  .howWeDoThings-content {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .howWeDoThings-content-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    padding-left: 10px;
  }

  .howWeDoThings-content-right img {
    width: 160px;
    height: auto;
    padding-left: 0;
  }

  .howWeDoThings-content-right h4 {
    padding-left: 0;
    margin: 8px 0 8px 0;
  }

  .howWeDoThings-content-right h1 {
    padding-left: 0;
    font-size: 1.3rem;
    margin: 8px 0 8px 0;
  }

  .howWeDoThings-content-right p {
    padding-left: 0;
    font-size: 1rem;
  }

  .howWeDoThings-content-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: end;
    padding-right: 10px;
  }

  .howWeDoThings-content-left img {
    width: 160px;
    height: auto;
    padding-right: 0;
  }

  .howWeDoThings-content-left h4 {
    padding-right: 0;
    margin: 8px 0 8px 0;
    padding-right: 0;
  }

  .howWeDoThings-content-left h1 {
    padding-right: 0;
    font-size: 1.3rem;
    margin: 8px 0 8px 0;
  }

  .howWeDoThings-content-left p {
    padding-right: 0;
    font-size: 1rem;
  }
}
