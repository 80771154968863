.letsWork {
    width: 100%;
    background-color: #2e2125;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */

}

.letsWork-content {
    /* border: 1px solid white; */
    margin: 3rem 4rem;
    padding: 1rem 2rem;
    text-align: center;
    max-width: 500px;
    
    
}

.letsWork-content h4 {
    color: #FB5E2A;
}

.letsWork-content h2 {
    color: #ffffff;
    font-size: 38px;
    margin: 0;
}

.letsWork-content p {
    color: #ffffff;
    font-size: 18px;
    margin: 0.9rem 0 1.5rem 0 ;
}


.btn-orange {
    padding: 8px 15px;
    margin: 0 0.5rem;
    font-size: 14px;
    background: transparent;
    color: #ffffff;
    letter-spacing: 1px;
    border: 1px solid #FB5E2A;
    font-weight: 400;
    cursor: pointer;
    white-space: nowrap;
  }

  .btn-orange a {
    text-decoration: none;
    color: #ffffff;
  }


  @media screen and (max-width: 960px) {
    .letsWork-content {
        margin: 2rem 3rem;
    }

    .letsWork-content h2 {
        font-size: 2rem;
        margin-bottom: 20px;
    }
  }