.emailForm-container {
  /* border: 1px solid black; */
  
}

.emailForm {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  width: 300px;
  padding: 30px 30px;
  border-radius: 5px;
}


textarea {
  border-radius: 2px;
  box-shadow: 1px 1px grey;
}
textarea:hover {
  background-color: beige;
}

input {
  height: 25px;
  border-radius: 2px;
  border-width: 0.1px;
  box-shadow: 1px 1px grey;
}

input:hover {
  background-color: beige;
}
.submit-btn {
  margin-top: 18px;
  width: 130px;
  height: 30px;
  margin-bottom: 10px;
  background-color: rgb(17, 17, 39);
  color: white;
  border-radius: 5px;
}

label {
  margin: 10px 0;
}

@media screen and (max-width: 960px) {
  .emailForm {
    width: 220px;
    margin: 2rem 0;
    
  }
}
