.aboutUsIntro {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #f3edfc;

}


.aboutUsIntro-content {
    width: 60rem;
    display: flex;
    flex-direction: column;
    padding: 10rem 0 22rem 18rem;  
} 


.aboutUsIntro-content h4 {
    font-size: 0.9rem;
    font-weight: 400;
    color:#533c76;
}

.aboutUsIntro-content a {
    text-decoration: none;
    color:#533c76;
}

.aboutUsIntro-content h1 {
    font-size: 3rem;
    margin: 1rem 0 1.5rem 0;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: normal;
    color: #393835;
    padding-left: 3rem;
    margin: 1rem 0 1rem 0;
}

.aboutUsIntro-content p {
    padding-left: 3rem;
    font-size: 1.15rem;
    line-height: 1.5;
    font-weight: 400;
    margin: 0.5rem 0 1rem 0;
}

@media screen and (max-width: 960px) {
    .aboutUsIntro-content {
        padding-left: 2rem;
        width: auto;
        padding-bottom: 18rem;
    }

    
        

    .aboutUsIntro-content h1 {
        padding-left: 1rem;
        font-size: 1.8rem;
    }

    .aboutUsIntro-content p {
        width: 300px;
        padding-left: 1rem;
    }
  }