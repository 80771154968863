.contact {
  width: 100%;
  background-color: #f3edfc;
  display: flex;
  flex-direction: column;
  padding: 2rem 0 2rem 0;
  align-items: center;
}

.contact-content {
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
}

.contact-content-left {
  width: 30rem;
  /* border: 1px solid black; */
  
}

.contact-content-left p {
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 0;
}


.followUs {
  margin: 6rem 0 2rem 0;
  text-align: center;
}

.social {
  margin: 1rem 0 1rem 0;
}

.followUs strong {
  font-weight: 600;
  font-size: 1.15rem;
}

.address {
  width: 100%;
  margin: 4rem 0 4rem 0;
  display: flex;
  justify-content: center;
  /* border: 1px solid black; */
}

.address-text {
  width: 30rem;
}

.address-location {
  width: 30rem;
  
}

.map-container {
  margin-left: 100px;
}

.address-text h4 {
font-weight: 600;
font-size: 1rem;
line-height: 1.6;
color: #000000;
}

.address-text h1 {
font-size: 2.4rem;
font-weight: bold;
line-height: 1.2;
letter-spacing: normal;
color: #000000;
margin-top: 1rem;
}

.address-text h5 {
font-size: 1.15rem;
font-weight: 600;
margin-bottom: 0.5rem;
}

.address-text p {
margin-top: 0.5rem;
}


.contact-content-right {
  width: 30rem;
  /* padding-top: 1rem; */
}

@media screen and (max-width: 960px) {
  .contact-content {
    display: flex;
    flex-direction: column;
  }

  .contact {
    padding-bottom: 0;
  }

  .address {
    display: flex;
    flex-direction: column;
  }

  .address-location {
    width: auto;
  }

  .map-container {
    margin: 30px 0 30px 0;
  }

  .contact-content-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .contact-content-left p {
    width: 300px;
  }

  .contact-content-right {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .address-text {
    width: 100%; 
  }

  .address-text h1 {
    font-size: 1.8rem;
    margin: 0.5rem 0;
    padding-left: 32px;
  }

  .address-text h4 {
    padding-left: 32px;
  }

  .address-text p {
    padding-left: 32px;
  }

  .address-text h5 {
    margin: 16px 0;
    padding-left: 32px;
  }

}
