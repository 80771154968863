.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem 4rem;
  }

  .card-container a {
    text-decoration: none;
  }

  @media screen and (max-width: 960px) {
    .card-container {
      padding: 2rem 0rem;
    }
  }