.portfolioIntro {
    width: 100%;
    background-color: #f3edfc;
    display: flex;
    flex-direction: column;
    
}

.portfolioIntro-content {
    padding: 10rem 0 4rem 18rem;
}

.portfolioIntro-content a {
    text-decoration: none;
    color: #533c76;
}


.portfolioIntro-content h4 {
    font-weight: 400;
    font-size: 0.9rem;
    color: #533c76;
}

.portfolioIntro-content h1{
    width: 22rem;
    color: #393835;
    padding-left: 4rem;
    font-size: 3rem;
    line-height: 1.2;
    font-weight: bold;
    letter-spacing: normal;
}