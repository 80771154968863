.portfolio {
    display: flex;
    flex-direction: column;
    background-color: #f3edfc;
    
}

.portfolio-content {
    display: flex;
    flex-wrap: wrap;
    /* overflow: hidden; */
}


.portfolio-item {
    display: flex;
    position: relative;
    transition: transform .2s;
    overflow: hidden;
}

.portfolio-item img {
    transition: transform .2s;
}

.portfolio-item:hover img {
    transform: scale(1.2);
}

.portfolio-item:hover::before {
    content: "PLAY VIDEO"; 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f24d33; 
    opacity: 0.7; 
    z-index: 1; 
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    padding-top: 30%;
    
}

.video-player-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; 
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    /* background-color: #ffffff; 
    color: #000000;  */
    border: none;
    padding: 10px;
    cursor: pointer;
    padding: 0;
  }


