.services-primary-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding: 4rem 0 4rem 0;
    background-color: #f3edfc;
}

.services-primary {
    display: flex;
    flex-direction: column;
    margin: 1rem 1rem 1rem 1rem;
}

.services-primary h4 {
    margin: 1rem 0 0 0;
}

.services-primary:hover img {
    transform: scale(1.1);
    transition: transform 0.5s;
}

.services-primary a {
    color: black;
    text-decoration: none;
}

@media screen and (max-width: 960px) {
    .services-primary-container {
        padding: 2rem 0 4rem 0;
    }
}